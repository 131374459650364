<template>
    <div class="batch-upload mb10">
        <div v-if="type != 'view'" class="batch-upload-header flex-row-between">
            <el-upload
                ref="batchUpload1"
                :multiple="true"
                action=""
                :accept="$config.acceptImg"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="(file, fileList) => onChange(file, fileList, 'batchUpload1')"
            >
                <el-button type="primary" size="mini">上传图片</el-button>
            </el-upload>
            <div class="flex-row">
                <div class="mr30">
                    黄色图片 <strong class="text-danger">{{greenStatData.yellow}}</strong> 张，鉴黄中 <strong class="text-warning">{{greenStatData.pending}}</strong> 张，通过鉴黄 <strong class="text-success">{{greenStatData.green}}</strong> 张
                </div>
                <div class="mr30" v-if="uploadData.uploadTotal !== 0">
                    本次上传：{{uploadData.uploadTotal}},
                    <span class="color-green" v-if="showSuccCount">成功{{uploadData.uploadSuccessNum}}</span>
                    <span class="color-red">失败{{uploadData.uploadErrorNum}}</span>
                </div>
                <div class="mr10">已上传 {{imageAddr.length}} </div>
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="checkAllHandle">全选</el-checkbox>&nbsp;
                <el-button type="text" @click="removeHandle">删除</el-button>
                <el-button v-if="imageAddr.length" type="text" size="mini" @click="previewHandle">预览</el-button>
            </div>
        </div>
        <el-checkbox-group class="batch-upload-imgbox flex-row" v-model="checkList" @change="checkSingleHandle">
            <div class="batch-upload-list flex-row" ref="imageList">
                <div class="batch-upload-item" v-for="(img, index) in imageAddr" :key="img + index">
                    <div class="batch-upload-img upload-bg">
                        <customerImage :greenprocess="img.greenStatus" :key="img.imgUrl" :src="$config.cdn + $utils.filterImagePath(img.imgUrl)" />
                    </div>
                    <el-tooltip effect="dark" :content="img.imgUrl | imgNameFilter" placement="top-start">
                        <el-checkbox class="single-ellipsis" v-if="type != 'view'" :label="img">{{img.imgUrl | imgNameFilter}}</el-checkbox>
                    </el-tooltip>
                </div>
                <el-upload
                    v-if="type != 'view'"
                    ref="batchUpload2"
                    drag
                    :multiple="true"
                    action=""
                    :accept="$config.acceptImg"
                    class="batch-upload-add"
                    :auto-upload="false"
                    :show-file-list="false"
                    :on-change="(file, fileList) => onChange(file, fileList, 'batchUpload2')"
                >
                    <p class="batch-upload-text">
                        <i class="el-icon-plus"></i>
                        <!-- <span>支持拖拽上传</span> -->
                    </p>
                </el-upload>
            </div>
        </el-checkbox-group>
    </div>
</template>

<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-18 09:13:33
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-09-22 10:02:59
 * @Description: 批量上传章节图片
 * @Company: 成都二次元动漫
 */
import { uploadMixin } from '@/mixins';
import customerImage from '@/components/customerImage';
import Sortable from 'sortablejs';

export default {
    mixins: [uploadMixin],
    props: {
        type: String, // 编辑类型: 0 view, 1 edit, 2 add
        imageAddr: {
            type: Array,
            default: () => []
        },
        showSuccCount: {
            type: Boolean,
            default: true // 是否显示上传成功条数显示
        }
    },
    components: {
        customerImage
    },
    data() {
        return {
            checkAll: false,
            isIndeterminate: false,
            checkList: [],
            uploadData: {
                uploadSuccessNum: 0,
                uploadErrorNum: 0,
                uploadTotal: 0,
                errorMessage: [],
                isEnd: true,
                isDrop: false,
                firstImgRaw: null,
                files: []
            }
        };
    },
    computed: {
        greenStatData() {
            let tmp = {};
            tmp.yellow = this.imageAddr.filter(item => {
                return item.greenStatus === 3;
            }).length;
            tmp.green = this.imageAddr.filter(item => {
                return item.greenStatus === 4;
            }).length;
            tmp.pending = this.imageAddr.filter(item => {
                return item.greenStatus === 1;
            }).length;
            return tmp;
        }
    },
    mounted() {
        this.dragTable = Sortable.create(this.$refs.imageList, {
            onUpdate: this.dragUpdateHandle
        });

        // element-ui bug，重写内部拖拽组件拖拽方法
        this.dropInstance = this.$refs.batchUpload2.$children[0].$children[0];
        this.dropInstance.onDrop = this.onDrop.bind(this.dropInstance);
    },
    watch: {
        imageAddr: {
            deep: true,
            handler() { // 获取第一张图片的高宽数据，用于上传其他图片时验证
                if (this.imageAddr && this.imageAddr.length > 0 && !this.uploadData.firstImgRaw) {
                    this.getFirstImgRaw(this.imageAddr[0]);
                }
            }
        }
    },
    methods: {
        getFirstImgRaw(image) {
            let img = new Image();
            img.onload = () => {
                this.setFirstImgRaw(img);
                img = null;
            };
            img.src = this.$config.cdn + this.$utils.filterImagePath(image.imgUrl) ;
        },
        // 设置第一张图片尺寸信息
        setFirstImgRaw(image, file) {
            if (!this.uploadData.firstImgRaw) {
                this.uploadData.firstImgRaw = {
                    uid: file && file.uid,
                    w: image.width,
                    h: image.height
                };
            }
        },
        clearFirstImgRaw(file) {
            if (!file) {
                this.uploadData.firstImgRaw = null;
                return;
            }
            // file存在的情况->清除同一批中第一张错误时，情况记录数据
            if (file && file.uid === this.uploadData.firstImgRaw.uid) {
                this.uploadData.firstImgRaw = null;
            }
        },
        onDrop(e) {
            const _this = this.dropInstance;
            if (_this.disabled || !_this.uploader) return;
            const accept = _this.uploader.accept;
            _this.dragover = false;
            if (!accept) {
                _this.$emit('file', e.dataTransfer.files);
                return;
            }
            const files = [].slice.call(e.dataTransfer.files).filter(file => {
                const { type, name } = file;
                const extension = name.indexOf('.') > -1
                    ? `.${name.split('.').pop()}`
                    : '';
                const baseType = type.replace(/\/.*$/, '');
                return accept.split(',').map(type => type.trim()).filter(type => type).some(acceptedType => {
                    if (/\..+$/.test(acceptedType)) {
                        return extension === acceptedType;
                    }
                    if (/\/\*$/.test(acceptedType)) {
                        return baseType === acceptedType.replace(/\/\*$/, '');
                    }
                    /* eslint-disable */
                    if (/^[^\/]+\/[^\/]+$/.test(acceptedType)) {
                        return type === acceptedType;
                    }
                    /* eslint-enable */
                    return false;
                });
            });

            this.uploadData = {
                ...this.uploadData,
                uploadTotal: files.length,
                isDrop: true
            };

            _this.$emit('file', files);
        },
        // 预览
        previewHandle() {
            this.$root.$emit('previewChapterContent', this.imageAddr);
        },
        // 拖拽排序更新
        dragUpdateHandle(dragData) {
            const { newIndex, oldIndex } = dragData;
            this.$emit('update:imageAddr', this.$utils.moveArrayItems(this.$utils.cloneDeep(this.imageAddr), newIndex, oldIndex));
        },
        // 选择所有
        checkAllHandle(val) {
            this.checkList = val ? this.imageAddr : [];
            this.checkAll = val;
            this.isIndeterminate = false;
        },
        // 选择单个
        checkSingleHandle(list) {
            let checkedCount = list.length;
            this.checkAll = checkedCount === this.imageAddr.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.imageAddr.length;
        },
        // 删除
        removeHandle() {
            const _imageAddr = this.$utils.cloneDeep(this.imageAddr);
            this.checkList.forEach(item => {
                let index = _imageAddr.findIndex(subItem => {
                    return subItem.imgUrl === item.imgUrl;
                });
                _imageAddr.splice(index, 1);
            });
            this.checkList = [];
            this.checkAll = false;
            this.isIndeterminate = false;
            if (_imageAddr.length === 0) {
                this.clearFirstImgRaw();
            }
            this.$emit('update:imageAddr', _imageAddr);
        },
        // 上传成功后的图片排序方法
        sortUploadedImage(list) {
            return list.sort((prev, next) => {
                const { getFileName, isNumber, getUnicode } = this.$utils;
                const prevName = getFileName(prev.imgUrl);
                const nextName = getFileName(next.imgUrl);
                let prevNameNum = parseInt(prevName);
                let nextNameNum = parseInt(nextName);
                if (!isNumber(prevNameNum)) prevNameNum = getUnicode(prevName);
                if (!isNumber(nextNameNum)) nextNameNum = getUnicode(nextName);
                return prevNameNum - nextNameNum;
            });
        },
        // 自定义上传方法
        uploadFunction(fileName, file) {
            const { comicId, chapterId, uploaderUid } = this.$route.query;
            this.getChapterContentToken({ comicId, chapterId, fileName, uploaderUid }).then(res => {
                this.uploadHandle({...res, file}).then(res => {
                    this.$emit('update:imageAddr', this.sortUploadedImage([...this.imageAddr, {
                        imgUrl: '/' + res.key
                    }]));
                    this.uploadSuccess(`/${res.key}`);
                }).catch(() => { // 如果上传失败，找到对应图片删除
                    this.clearFirstImgRaw(file);
                });
            }).catch(() => {
                this.clearFirstImgRaw(file);
            });
        },
        // 进行图片鉴黄
        async insertgreenImage() {
            const { comicId, chapterId } = this.$route.query;
            return this.$api('insertgreenImage', { comicId, chapterId: chapterId || 0, images: this.uploadData.files, imgType: 'chapter_content' })
                .then(res => {
                    let list = Array.isArray(res.data) ? res.data : [];
                    list.map(item => {
                        this.imageAddr.map(subItem => {
                            if (subItem.imgUrl === item.imgUrl) {
                                Object.assign(subItem, item);
                            }
                        });
                    });
                    this.$emit('update:imageAddr', this.sortUploadedImage(this.imageAddr));
                });
        },
        // 上传完成
        uploadEnd() {
            let { errorMessage, uploadErrorNum, uploadSuccessNum, uploadTotal } = this.uploadData;
            if (uploadTotal !== (uploadErrorNum + uploadSuccessNum)) return;
            if (uploadTotal === (uploadErrorNum + uploadSuccessNum)) this.uploadData.isEnd = true;
            this.$nextTick(async () => {
                // 全部上传完成后进行鉴黄
                await this.insertgreenImage();
                this.$emit('uploaded');
            });
            if (!errorMessage.length) return;
            this.$notify.error({
                title: '图片错误',
                duration: 0,
                dangerouslyUseHTMLString: true,
                message: errorMessage.map(group => `<strong>名称：</strong>${group.name}<br /><strong>错误信息：</strong>${group.message.map(err => err).join('、')}`).join('<br />')
            });
        },
        // 上传错误
        uploadError(errorData) {
            this.uploadData.errorMessage.push(errorData);
            this.uploadData.uploadErrorNum += 1;
            this.uploadEnd();
        },
        // 上传成功
        uploadSuccess(url) {
            this.uploadData.uploadSuccessNum += 1;
            this.uploadData.files.push(url);
            this.uploadEnd();
        },
        // 图片选择
        onChange(file, fileList, refName) {
            if (this.uploadData.isEnd) {
                // 新的一次批量上传需要重置数据
                const { isDrop, uploadTotal, firstImgRaw } = this.uploadData;
                const _data = this.$options.data();
                this.uploadData = {
                    ..._data.uploadData,
                    uploadTotal: isDrop ? uploadTotal : this.$refs[refName].$el.querySelector('.el-upload__input').files.length,
                    isDrop,
                    isEnd: false,
                    firstImgRaw,
                    files: []
                };
            }
            // element-ui的upload组件需要清空上传列表，否则多选的时候会重复
            this.$refs[refName].clearFiles();
            this.validateImage(file).then(validInfo => {
                const { message } = validInfo;
                if (message.length) {
                    this.uploadError(validInfo);
                } else {
                    this.setFirstImgRaw({ width: validInfo.width, height: validInfo.height }, file);
                    this.uploadFunction(file.name, file.raw);
                };
            });
        },
        // 图片校验
        validateImage(item) {
            const errorData = { name: item.name, message: [] };
            return this.validateImageName(item, errorData).then(nameErrorData => {
                if (nameErrorData.message.length) return nameErrorData;
                else return this.validateImageFormat(item, nameErrorData);
            });
        },
        // 新增图片名称校验，交由后端接口统一校验便于维护，2019-3-20
        validateImageName(item, errorData) {
            const { comicId } = this.$route.query;
            return this.$api('checkimagesformat', { comicId, imageAddr: [item.name] }).then(res => {
                if (this.$utils.isArray(res.data)) {
                    res.data.forEach(ele => {
                        if (!ele.islegal) errorData.message.push('图片名称格式错误！');
                    });
                }
                return errorData;
            });
        },
        // 验证图片格式、大小
        validateImageFormat(item, errorData) {
            const isRt2M = item.size / 1024 / 1024 > 2;

            return this.$utils.getImagePromise(item.raw).then(image => {
                const { width, height } = image;
                errorData.width = width;
                errorData.height = height;

                if (isRt2M) errorData.message.push('请上传小于2MB的图片');
                if (!/(.jpg|.png|jpeg|gif)$/i.test(item.name)) errorData.message.push('上传图片格式错误');
                if (width > 2000 || width < 800) errorData.message.push('图片宽度不正确');
                if (height > width * 3) errorData.message.push('图片高度不正确');
                const checkRes = this.checkRawInfo(image);
                if (checkRes) {
                    errorData.message.push(checkRes);
                }
                return errorData;
            });
        },
        checkRawInfo(image) {
            const { width } = image;
            const firstImgRaw = this.uploadData.firstImgRaw;
            if (firstImgRaw) { // 第一条上传成功的图片信息数据
                const { w } = firstImgRaw;
                if (width !== w) {
                    return '图片宽度与第一张图片不一致';
                }
            }
            return '';
        },
        // 图片错误提示
        imageErrorMessage(errorList) {
            if (!errorList.length) return;
            this.$msgbox({
                title: '消息',
                message: errorList.map(item => `${item.name}: ${item.message} \n`).join(''),
                showCancelButton: false,
                showConfirmButton: false
            });
        }
    }
};
</script>

<style lang="scss">
    .batch-upload{
        border: 1px solid #dcdfe6;
        &-header{
            padding: 8px 10px;
            background-color: #ebeef5;
            border-bottom: 1px solid #dcdfe6;
        }
        &-imgbox{
            padding: 20px;
            flex-flow: row wrap;
        }
        &-item{
            width: 140px;
            box-sizing: border-box;
            margin: 0 1% 20px;
            background-color: #fff;
        }
        div.batch-upload-list{
            width: 100%;
            flex-wrap: wrap;
        }
        &-img{
            position: relative;
            width: 100%;
            height: 140px;
            // background-color: #e4e7ea;
            border: 1px solid #e4e7ea;
            cursor: move;
            // overflow: hidden;
            margin-bottom: 10px;
            img{
                width: 100%;
            }
        }
        &-add{
            width: 140px;
            cursor: pointer;
            color: #999;
            font-size: 14px;
            margin: 0 1% 20px;
            .el-upload{
                width: 100%;
                height: 190px;
                border: 1px solid #e4e7ea;
                .el-upload-dragger{
                    width: 100%;
                    height: 190px;
                    border: none;
                    background: #e4e7ea;
                    border-radius: 0;
                }
            }
        }
        // &-btn {
        //     color: #ffffff;
        //     :focus {
        //         border-color: #409EFF;
        //         color: #ffffff;
        //     }
        // }
        &-text{
            position: absolute;
            width: 90px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            i{
                display: block;
                color: #aaa;
                font-size: 32px;
            }
        }
    }
</style>
