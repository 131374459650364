<template>
    <el-form class="chapter-focus" :model="focus" label-width="120px" element-loading-background="rgba(0, 0, 0, 0.75)">
        <el-form-item label="看点内容：" prop="content">
            <template v-if="type === 'view'">{{focus.content}}</template>
            <el-input v-else v-model="focus.content" :minlength="10" :maxlength="20" placeholder="请输入章节看点内容，字数在10-20个字之间"/>
        </el-form-item>
        <el-form-item label="选择字体颜色：" prop="fontColor">
            <colorSelect :type="type" classify="fontColor" :color.sync="focus.fontColor"  :intro.sync="focus"/>
        </el-form-item>
        <el-form-item label="选择描边颜色：" prop="borderColor">
            <colorSelect :type="type" classify="borderColor" :color.sync="focus.borderColor" :intro.sync="focus" />
        </el-form-item>
        <div class="flex-row">
            <el-form-item label="字体大小：" prop="fontSize" inline>
                <!-- <template v-if="type === 'view'">{{focus.fontSize}}</template>
                <el-input-number v-else v-model="focus.fontSize" :min="0" :max="50"></el-input-number> -->
                <template>{{focus.fontSize}}</template>
            </el-form-item>
            <el-form-item label="描边大小：" prop="borderSize" inline>
                <!-- <template v-if="type === 'view'">{{focus.borderSize}}</template>
                <el-input-number v-else v-model="focus.borderSize" :min="0" :max="20"></el-input-number> -->
                <template>{{focus.borderSize}}</template>
            </el-form-item>
        </div>
        <el-form-item label="看点位置：" prop="position">
            <template v-if="type === 'view'">{{focus.position | chapterIntroPositionFilter}}</template>
            <el-radio-group v-else v-model="focus.position">
                <el-radio :label="1">靠左</el-radio>
                <el-radio :label="2">居中</el-radio>
                <el-radio :label="3">靠右</el-radio>
            </el-radio-group>
        </el-form-item>
    </el-form>
</template>

<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-18 15:23:22
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-09-23 14:52:57
 * @Description: 章节看点
 * @Company: 成都二次元动漫
 */
import colorSelect from '@/components/colorSelect';
export default {
    props: {
        type: String, // 编辑类型: 0 view, 1 edit, 2 add
        intro: {
            type: Object,
            default: () => {}
        }
    },
    watch: {
        intro: {
            deep: true,
            immediate: true,
            handler(val, oldVal) {
                this.focus = val;
            }
        },
        focus: {
            deep: true,
            handler(val, oldVal) {
                this.$emit('update:intro', val);
            }
        }
    },
    data() {
        return {
            focus: {}
        };
    },
    components: {
        colorSelect
    }
};
</script>

<style lang="scss" scoped>
    .chapter-focus{
        border: 1px dashed #dcdfe6;
        padding: 20px;
    }
</style>
