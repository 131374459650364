<template>
    <div class="color-select flex-row">
        <ul class="color-select-default flex-row">
            <li v-for="item in defaultColors" :key="item" :class="{ ischecked: color === item }" :style="{background: item}" @click="selectHandle(item)"></li>
        </ul>
        <el-input class="color-select-input" :readonly="type === 'view'" v-model="color" placeholder="请输入颜色">
            <!-- <i slot="prefix">#</i> -->
        </el-input>
        <el-popover
            v-if="type !== 'view'"
            ref="popover"
            placement="right"
            width="220"
            trigger="click">
            <photoshop-picker ref="pspicker" :value="hex" @input="updateValue"></photoshop-picker>
        </el-popover>
        <div class="color-select-box flex-row-between" v-popover:popover>
            <div :style="{ background: color }"></div>
            <i class="el-icon-arrow-down"></i>
        </div>
    </div>
</template>

<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-18 18:03:48
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-16 16:21:32
 * @Description: 颜色选择组件
 * @Company: 成都二次元动漫
 */
import { Sketch } from 'vue-color';
export default {
    props: {
        type: String, // 编辑类型: 0 view, 1 edit, 2 add
        classify: String, // 颜色选择器类别 fontColor borderColor
        defaultColors: {
            type: Array,
            default: () => ['#ffffff', '#342065', '#8e522b', '#6c2f75', '#376b3a', '#3d9cc0', '#a94551']
        },
        intro: Object,
        color: {
            type: String,
            default: '#ffffff'
        }
    },
    data() {
        return {
            hex: '#ffffff'
        };
    },
    watch: {
        color(newValue, oldValue) {
            if (newValue && this.checkoutEqual()) {
                this.$emit('update:color', oldValue);
                return this.$message.error('两个颜色不能相同');
            } else if (this.intro.fontColor !== '#ffffff' && this.intro.borderColor !== '#ffffff') {
                // 必须有一个为白色
                if (this.classify === 'fontColor') return this.$emit('update:intro', { ...this.intro, borderColor: '#ffffff' });
                if (this.classify === 'borderColor') return this.$emit('update:intro', { ...this.intro, fontColor: '#ffffff' });
            }
            this.hex = newValue || '#ffffff';
        },
        hex(newValue, oldValue) {
            this.$emit('update:color', newValue ? newValue.toLocaleLowerCase() : '#ffffff');
        }
    },
    methods: {
        selectHandle(item) {
            if (this.type === 'view') return;
            this.$emit('update:color', item || '#ffffff');
        },
        updateValue({ hex }) {
            if (this.type === 'view') return;
            this.$emit('update:color', hex ? hex.toLocaleLowerCase() : '#ffffff');
        },
        // 检查是否字体颜色是否等于表变颜色
        checkoutEqual() {
            return this.intro.fontColor === this.intro.borderColor;
        }
    },
    components: {
        'photoshop-picker': Sketch
    }
};
</script>

<style lang="scss" scoped>
    .color-select{
        align-items: center;
        &-default{
            li{
                width: 32px;
                height: 32px;
                margin-right: 10px;
                border-radius: 3px;
                cursor: pointer;
                border: 1px solid #cccccc;
                &.ischecked{
                    box-shadow: 0 0 5px #342065;
                }
            }
        }
        &-input{
            width: 120px;
            font-size: 12px;
        }
        &-box{
            height: 32px;
            padding: 5px 10px;
            box-sizing: border-box;
            margin-left: 10px;
            border-radius: 4px;
            border: 1px solid #dcdfe6;
            color: #dcdfe6;
            cursor: pointer;
            &>div{
                width: 32px;
                height: 100%;
                margin-right: 10px;
                border: 1px solid #cccccc;
            }
        }
    }
</style>
